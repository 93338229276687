import { RootObject } from "./order.interface";
import { reactive, ref } from "vue";
import axios from "axios";
import router from "@/router/index";
import { number } from "maz-ui";
import { countNewOrders, countAllOrders, orderToPrint } from '@/shared/state';

export interface FetchState<T> {
  isLoading: boolean;
  error?: string;
  data: T | null;
  orderHistory: T | null;
  current_page: null;
  last_page: null;
  total: any;
  selectedPrintingType: any;
  printerId: any;
}

export const orderService = (uuid: string | string[]) => {
  // State

  const ordersState = reactive<FetchState<RootObject>>({
    isLoading: false,
    error: "",
    data: null,
    orderHistory: null,
    current_page: null,
    last_page: null,
    total: null,
    selectedPrintingType: null,
    printerId: null,
  });

  const UpdaterderState = reactive({
    isLoading: false,
    error: "",
    data: null,
    status: 0,
    total: 0,
  });
  ordersState.isLoading = true;
  // ==> Methodes
  // Get all products
  const getOrders = async () => {
    ordersState.isLoading = true;
    ordersState.error = "";
    try {
      await axios
        .get("orders/" + uuid)
        .then(async (response) => {
          if (response.status === 200) {
            const json = response.data.data;
            ordersState.data = json;
            ordersState.isLoading = false;
            if (ordersState.data) {
              const at_place_length = ordersState?.data?.pending?.AT_PLACE?.filter(order => order?.status == "pending")?.length || 0
              const liv_length = ordersState?.data?.pending?.LIV?.filter(order => order?.status == "pending")?.length || 0
              const cac_length = ordersState?.data?.pending?.CAC?.filter(order => order?.status == "pending")?.length || 0
              const all_at_place_length = ordersState?.data?.pending?.AT_PLACE?.filter(order => order?.status == "pending")?.length || 0
              const all_liv_length = ordersState?.data?.pending?.LIV?.filter(order => order?.status == "pending")?.length || 0
              const all_cac_length = ordersState?.data?.pending?.CAC?.filter(order => order?.status == "pending")?.length || 0
              const countPendingOrders = at_place_length + liv_length + cac_length
              const countOrders = all_at_place_length + all_liv_length + all_cac_length


              orderToPrint.value = ordersState?.data?.accepted?.LIV?.concat(
                ordersState?.data?.accepted?.CAC,
                ordersState?.data?.accepted?.AT_PLACE,
                ordersState?.data?.pending?.LIV,
                ordersState?.data?.pending?.CAC,
                ordersState?.data?.pending?.AT_PLACE,
                ordersState?.data?.delivered?.LIV,
                ordersState?.data?.delivered?.CAC,
                ordersState?.data?.delivered?.AT_PLACE
              ).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).find(order => !order.printed);



              countNewOrders.value = countPendingOrders || 0
              countAllOrders.value = countOrders || 0
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            ordersState.error = error.response.data.message;
            ordersState.isLoading = false;
            ordersState.data = null;
          } else {
            ordersState.error = error;
            ordersState.isLoading = false;
            ordersState.data = null;
          }
        });
    } catch (error: any) {
      ordersState.error = error;
      ordersState.isLoading = false;
      ordersState.data = null;
    }
  };
  const getHistoryOrders = async (page) => {
    ordersState.isLoading = true;
    ordersState.error = "";
    try {
      await axios
        .get("historiques-orders/" + uuid + "?page=" + page)
        .then((response) => {
          if (response.status === 200) {
            const json = response.data.data.data;

            ordersState.orderHistory = json;
            ordersState.current_page = response.data.data.current_page;
            ordersState.total = response.data.data.total;
            ordersState.last_page = response.data.data.last_page;
            ordersState.isLoading = false;
          }
        })
        .catch((error) => {
          if (error.response) {
            ordersState.error = error.response.data.message;
            ordersState.isLoading = false;
            ordersState.orderHistory = null;
          } else {
            ordersState.error = error;
            ordersState.isLoading = false;
            ordersState.orderHistory = null;
          }
        });
    } catch (error: any) {
      ordersState.error = error;
      ordersState.isLoading = false;
      ordersState.data = null;
    }
  };

  const updateOrder = async (paylod: any) => {
    UpdaterderState.isLoading = true;
    UpdaterderState.data = null;
    UpdaterderState.status = 0;

    try {
      await axios
        .post(`orders/${uuid}/status`, paylod)
        .then((response) => {
          if (response.status === 200) {
            const json = response.data.data;
            UpdaterderState.data = json;
            UpdaterderState.isLoading = false;
            UpdaterderState.status = 200;
            getOrders();
          }
        })
        .catch((error) => {
          if (error.response) {
            UpdaterderState.error = error.response.data.message;
            UpdaterderState.isLoading = false;
            UpdaterderState.data = null;
          } else {
            UpdaterderState.error = error;
            ordersState.isLoading = false;
            UpdaterderState.data = null;
          }
        });
    } catch (error: any) {
      UpdaterderState.error = error;
      UpdaterderState.isLoading = false;
      UpdaterderState.data = null;
    }
  };
  const updatePrintOrder = async (paylod: any) => {
    UpdaterderState.isLoading = true;
    UpdaterderState.data = null;
    UpdaterderState.status = 0;

    try {
      await axios
        .post(`orders/${uuid}/printed`, paylod)
        .then((response) => {
          if (response.status === 200) {
            const json = response.data.data;
            UpdaterderState.data = json;
            UpdaterderState.isLoading = false;
            UpdaterderState.status = 200;
          }
        })
        .catch((error) => {
          if (error.response) {
            UpdaterderState.error = error.response.data.message;
            UpdaterderState.isLoading = false;
            UpdaterderState.data = null;
          } else {
            UpdaterderState.error = error;
            ordersState.isLoading = false;
            UpdaterderState.data = null;
          }
        });
    } catch (error: any) {
      UpdaterderState.error = error;
      UpdaterderState.isLoading = false;
      UpdaterderState.data = null;
    }
  };
  const getPrintSelectedType = async () => {
    try {
      await axios
        .get("impressions/" + uuid)
        .then((response) => {
          if (response.status === 200) {
            const json = response.data.data;
            if (!json) {
              addPrintingSelectedType({
                type: "0"
              });
            } else {
              ordersState.printerId = json.id
              ordersState.selectedPrintingType = json.type
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            ordersState.error = error.response.data.message;
          } else {
            ordersState.error = error;
            ordersState.isLoading = false;
            ordersState.data = null;
          }
        });
    } catch (error: any) {
      ordersState.error = error;
      ordersState.isLoading = false;
      ordersState.data = null;
    }
  };
  const updatePrintingSelectedType = async (paylod: any) => {
    try {
      await axios
        .put(`impressions/${paylod.id}/${uuid}`, paylod)
        .then((response) => {
          if (response.status === 200) {
            getPrintSelectedType();
          }
        })
        .catch((error) => {
        });
    } catch (error: any) {
    }
  };
  const addPrintingSelectedType = async (paylod: any) => {
    try {
      await axios
        .post(`impressions/${uuid}`, paylod)
        .then((response) => {
          if (response.status === 200) {

            getPrintSelectedType();
          }
        })
        .catch((error) => {
          if (error.response) {
            UpdaterderState.error = error.response.data.message;
            UpdaterderState.isLoading = false;
            UpdaterderState.data = null;
          } else {
            UpdaterderState.error = error;
            ordersState.isLoading = false;
            UpdaterderState.data = null;
          }
        });
    } catch (error: any) {
      UpdaterderState.error = error;
      UpdaterderState.isLoading = false;
      UpdaterderState.data = null;
    }
  };




  return { ordersState, UpdaterderState, getOrders, updateOrder, updatePrintOrder, getHistoryOrders, getPrintSelectedType, updatePrintingSelectedType };
};

