
import { defineComponent, computed } from 'vue';
/*composables & states */
import { singleOrder, userUUID } from '@/shared/state';


export default defineComponent({
    setup() {
        console.log('defineComponent productssssssssssssssssssssssssssssssss')
        const order = computed(() => singleOrder.data);
        return {
            userUUID,
            singleOrder,
            order
        };
    },
});
