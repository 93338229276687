import axios from 'axios';

const API_URL = process.env.VUE_APP_ROOT_API;

class AuthService {
  login (user : any) {
    return axios
      .post('signin', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        return response.data;
      });
  }

  logout () {
    return axios.post('signout')
  }

  register(user : any) {
    return axios.post(API_URL + 'api/signup', {
      phone: user.phone,
      email: user.email,
      password: user.password,
      password_confirmation : user.password,
      firstName: user.firstName,
      lastName: user.lastName,
      cityID:user.city,
      storeType:user.storeType,
      storeName: "********",
    });
  }
  // recoverPassword(user : any) {
  //   return axios.post(API_URL + 'password/recover', {
  //     email: user.email;
  //   });
  // }
  // resetPassword(user : any) {
  //   //console.log(user);
  //   return axios.post(API_URL + 'password/reset', {
  //     //email: user.email,
  //     token: user.tokenID,
  //     password: user.password,
  //     password_confirmation: user.confirmationPassword
  //   });
  // }
  // verifyEmail(user : any) {
  //   return axios.post(API_URL + 'email/verify', {
  //     id: user.ID,
  //     hash: user.hash
  //   });
  // }

}

export default new AuthService();
