
import { defineComponent, computed } from 'vue';

/*composables & states */
import { singleOrder, userUUID } from '@/shared/state';
import { useModal } from '@/composables/modal/useModal';
import { usePrint } from "@/composables/global/usePrint"



export default defineComponent({
    name: 'index-modal',
    setup() {

        const order = computed(() => singleOrder.data);
        const { printOrderTicket } = usePrint();
        const { openModal, closeModal } = useModal();
        const print = (order) => {
            printOrderTicket([order])
        }
        return {
            openModal,
            closeModal,
            userUUID,
            order,
            print
        };
    },
});
