import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "elementCheckbox checkboxColor" }
const _hoisted_2 = ["checked", "value", "disabled"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      onChange: _cache[0] || (_cache[0] = ($event: any) => ($options.CheckboxButtonSubProduct())),
      checked: $props.subProduct.checked,
      value: $props.subProduct.id,
      class: _normalizeClass({
        'btn-disabled cursor-no-drop ': $props.group.IsDisabled,
      }),
      disabled: $props.group.IsDisabled && $props.subProduct.quantity == 0
    }, null, 42, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass([{
      checked: $props.subProduct.checked,
    }, "font-weight-300 first-letter-maj"])
    }, [
      _createTextVNode(_toDisplayString($props.subProduct?.ingredient?.name || $props.subProduct?.supplement?.name ||
  $props.subProduct?.name) + " ", 1),
      ($props.subProduct.unit_price > 0)
        ? (_openBlock(), _createElementBlock("em", _hoisted_3, _toDisplayString($props.subProduct.unit_price) + "€ ", 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}