<template>
    <button @click.prevent="eventHandle(page)">
        edit
    </button>
</template>

<script setup>
import { defineEmits } from 'vue';

const emits = defineEmits(['edit']);
const eventHandle = () => {
    emits('edit');
};
</script>