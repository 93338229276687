import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "mb-0 pb-0 d-block"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.promos)
    ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        class: _normalizeClass($props.className)
      }, [
        ($props.promos.length == 1 && $props.promos[0].sub_product_id == null && $props.promos[0].size == null
      )
          ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
              ($props.promos[0].min == 0 && $props.promos[0].free == 0)
                ? (_openBlock(), _createElementBlock("small", _hoisted_2, " - " + _toDisplayString($props.promos[0].percentage) + "% ", 1))
                : ($props.promos[0].min > 0 && $props.promos[0].free > 0 && $props.promos[0].percentage == 100)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_3, " Achetez " + _toDisplayString($props.promos[0].min) + ", Obtenez " + _toDisplayString($props.promos[0].free) + " Gratuit(s) ", 1))
                  : ($props.promos[0].min > 0 && $props.promos[0].free > 0 && $props.promos[0].percentage > 0)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_4, " Achetez " + _toDisplayString($props.promos[0].min) + ", Obtenez -" + _toDisplayString($props.promos[0].percentage) + "% sur " + _toDisplayString($props.promos[0].free) + " article(s) ", 1))
                    : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("label", _hoisted_5, " Promo sur les tailles "))
      ], 2))
    : ($props.promoSize)
      ? (_openBlock(), _createElementBlock("label", _hoisted_6, [
          ($props.promoSize.min == 0 && $props.promoSize.free == 0)
            ? (_openBlock(), _createElementBlock("small", _hoisted_7, " - " + _toDisplayString($props.promoSize.percentage) + "% ", 1))
            : ($props.promoSize.min > 0 && $props.promoSize.free > 0 && $props.promoSize.percentage == 100)
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, " Achetez " + _toDisplayString($props.promoSize.min) + ", Obtenez " + _toDisplayString($props.promoSize.free) + " Gratuit(s) ", 1))
              : ($props.promoSize.min > 0 && $props.promoSize.free > 0 && $props.promoSize.percentage > 0)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, " Achetez " + _toDisplayString($props.promoSize.min) + ", Obtenez -" + _toDisplayString($props.promoSize.percentage) + "% sur " + _toDisplayString($props.promoSize.free) + " article(s) ", 1))
                : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
}