
import { defineComponent, computed } from 'vue';
import { printOrderElementOrder, userUUID, storeInfoState } from '@/shared/state';
import {
} from '@/utils/global';

/*composables & states */
import { useGlobal } from "@/composables/global/useGlobal"

/** Services */

export default defineComponent({
    name: 'SomeComponent',
    setup() {
        const { formatDate } = useGlobal();
        const statePrintElementOrder = computed(() => printOrderElementOrder.data);
        return {

            userUUID,

            statePrintElementOrder,
            formatDate,

            storeInfoState

        };

    },

});
