<template>
  <nav class="navbar">
    <a class="sidebar-toggler" @click="toggle()">
      <font-awesome-icon icon="bars" size="lg" id="messageDropdowns" role="button" data-bs-toggle="dropdowns"
        aria-haspopup="true" aria-expanded="false" />
    </a>
    <div class="navbar-content">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a v-if="!stripeIsEnabled" :href="stripeLink" class="btn btn-primary text-wh">
            Activer mon compte Stripe
          </a>
          <span v-else-if="stripeMessage != null">
            Stripe est activé
          </span>
        </li>
        <li class="nav-item dropdown" v-if="false">
          <a class="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="flag-icon flag-icon-fr mt-1" title="fr"></i>
            <span class="ms-1 me-1 d-none d-md-inline-block">French</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="languageDropdown">
            <a href="javascript:;" class="dropdown-item py-2"><i class="flag-icon flag-icon-us" title="us" id="us"></i>
              <span class="ms-1"> English </span></a>
            <a href="javascript:;" class="dropdown-item py-2"><i class="flag-icon flag-icon-fr" title="fr" id="fr"></i>
              <span class="ms-1"> French </span></a>
          </div>
        </li>

        <!-- <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item  dropdown alerts alerts-orders">
          <a @click="stopAudios" class="nav-link cursor-pointer"
            :class="[{ 'alerts-shakes': (countNewOrders > 0) && audio }, { 'newOrders': (countNewOrders > 0) }]">
            <font-awesome-icon v-if="audio" icon="bell" size="lg" class="link-icon" />
            <font-awesome-icon v-else icon="bell-slash" size="lg" class="link-icon" />
            <span class="order order-numbers">{{ countNewOrders }} </span>
          </a>
        </li> -->
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item  dropdown alerts alerts-orders">
          <a @click="stopAudio" class="nav-link cursor-pointer"
            :class="[{ 'alerts-shakes': (countPendingOrders > 0) && audio }, { 'newOrders': (countPendingOrders > 0) }]">
            <font-awesome-icon v-if="audio" icon="bell" size="lg" class="link-icon" />
            <font-awesome-icon v-else icon="bell-slash" size="lg" class="link-icon" />
            <span class="order order-numbers">{{ countPendingOrders }} </span>
          </a>
        </li>
        <!-- <li class="nav-item dropdown">
          <font-awesome-icon
            icon="table"
            size="lg"
            id="appsDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu p-0" aria-labelledby="appsDropdown">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p class="mb-0 fw-bold">Web Apps</p>
              <a href="javascript:;" class="text-muted">Edit</a>
            </div>
            <div class="row g-0 p-1">
              <div class="col-3 text-center">
                <a
                  href="pages/apps/chat.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="message-square" class="icon-lg mb-1"></i>

                  <p class="tx-12">Chat</p></a
                  >
              </div>
              <div class="col-3 text-center">
                <a
                  href="pages/apps/calendar.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="calendar" class="icon-lg mb-1"></i>
                  <p class="tx-12">Calendar</p></a
                >
              </div>
              <div class="col-3 text-center">
                <a
                  href="pages/email/inbox.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="mail" class="icon-lg mb-1"></i>
                  <p class="tx-12">Email</p></a
                >
              </div>
              <div class="col-3 text-center">
                <a
                  href="pages/general/profile.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="instagram" class="icon-lg mb-1"></i>
                  <p class="tx-12">Profile</p></a
                >
              </div>
            </div>
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
            >
              <a href="javascript:;">View all</a>
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <font-awesome-icon
            icon="tasks"
            size="lg"
            id="messageDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />

          <div class="dropdown-menu p-0" aria-labelledby="messageDropdown">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>9 New Messages</p>
              <a href="javascript:;" class="text-muted">Clear all</a>
            </div>
            <div class="p-1">
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Leonardo Payne</p>
                    <p class="tx-12 text-muted">Project status</p>
                  </div>
                  <p class="tx-12 text-muted">2 min ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Carl Henson</p>
                    <p class="tx-12 text-muted">Client meeting</p>
                  </div>
                  <p class="tx-12 text-muted">30 min ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Jensen Combs</p>
                    <p class="tx-12 text-muted">Project updates</p>
                  </div>
                  <p class="tx-12 text-muted">1 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Amiah Burton</p>
                    <p class="tx-12 text-muted">Project deatline</p>
                  </div>
                  <p class="tx-12 text-muted">2 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Yaretzi Mayo</p>
                    <p class="tx-12 text-muted">New record</p>
                  </div>
                  <p class="tx-12 text-muted">5 hrs ago</p>
                </div>
              </a>
            </div>
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
            >
              <a href="javascript:;">View all</a>
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="notificationDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <font-awesome-icon icon="bell" size="lg" />
            <div class="indicator">
              <div class="circle"></div>
            </div>
          </a>
          <div class="dropdown-menu p-0" aria-labelledby="notificationDropdown">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>6 New Notifications</p>
              <a href="javascript:;" class="text-muted">Clear all</a>
            </div>
            <div class="p-1">
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="gift"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Ajouter les information de votre store</p>
                  <p class="tx-12 text-muted">30 min ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="alert-circle"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Configuration stripe</p>
                  <p class="tx-12 text-muted">1 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="flex-grow-1 me-2">
                  <p>ajouter votre premier produit</p>
                  <p class="tx-12 text-muted">2 sec ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="layers"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Configuration des horaire de travaill</p>
                  <p class="tx-12 text-muted">5 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="download"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Accepter votre premier commande</p>
                  <p class="tx-12 text-muted">6 hrs ago</p>
                </div>
              </a>
            </div>
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
            >
              <a href="javascript:;">View all</a>
            </div>
          </div>
        </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img class="wd-30 ht-30 rounded-circle" src="@/assets/img/user.png" alt="profile" />
          </a>
          <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="mb-3">
                <img class="wd-80 ht-80 rounded-circle" src="@/assets/img/user.png" alt="" />
              </div>
              <div class="text-center">
                <p class="tx-16 fw-bolder" v-if="selectedUser.storeName">{{ selectedUser.storeName }} </p>

                <p class="tx-16 fw-bolder" v-if="!selectedUser.storeName">{{ user.first_name }} {{ user.last_name }}
                </p>
                <p class="tx-12 text-muted" v-if="!selectedUser.storeName">{{ user.email }}</p>
              </div>
            </div>
            <ul class="list-unstyled p-1">
              <li>
                <router-link :to="{ name: 'profile', params: { UserUUID: userUUID } }"
                  class="dropdown-item py-2 pointer">
                  <span class="text-body ms-0">
                    <font-awesome-icon icon="user" size="lg" class="me-2 icon-md" />
                    <span>Profil</span>
                  </span>
                </router-link>
              </li>
              <li class="dropdown-item py-2 pointer" @click.prevent="logOut">
                <a class="text-body ms-0">
                  <font-awesome-icon icon="sign-out-alt" size="lg" class="me-2 icon-md" />
                  <span>Déconnexion</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { stripeSrevice } from "@/services/stripe/stripe.service"
import { mapGetters, mapState } from 'vuex';
import store from '@/store';
import { watch } from 'vue';
import router from "@/router/index";
import { useRoute } from 'vue-router';
import { reactive } from '@vue/reactivity';
import Cookies from 'js-cookie';
import { orderService } from "../../services/order/order.service";
import { playSounds, stopAudios } from '@/utils/global';
import { countNewOrders, orderToPrint, countAllOrders, audio } from '@/shared/state';

import { userUUID, currentUser, countPendingOrders } from '@/shared/state';
import { useGlobal } from '@/composables/global/useGlobal';

export default {
  name: "app-navebar",
  components: {},

  setup() {
    let interval = ref(null)
    const route = useRoute();
    const { stopAudio } = useGlobal();


    const selectedUser = ref({
      storeName: ""
    });
    const stripeState = reactive({
      isLoading: false,
      error: '',
      data: null,
    });

    const stripeLink = ref(null);
    const stripeIsEnabled = ref(true)
    const stripeMessage = ref(null)

    const user = ref({
      first_name: "",
      last_name: "",
      email: "",
    });


    let userUUID = ref(router.currentRoute.value.params.UserUUID)
    let currentRouteName = ref(router.currentRoute.value.name)
    //const getUID = computed(() => router.currentRoute.value.params.UserUUID);
    const isRoleAdmin = computed(() => userUUID.value != store.getters['auth/getUuid']);
    const isRoleSuperAdmin = computed(() => store.getters['auth/role']);

    // Watch route changes and adjust interval accordingly
    watch(route, (to, from) => {
      userUUID.value = to.params.UserUUID
      currentRouteName.value = to.name
      if (to.name == "users") {
        stopAudios();
      }
      if (to.name != 'orders' && isRoleAdmin.value) {
        const { getOrders } = orderService(userUUID.value);
        interval.value = window.setInterval(function () {
          getOrders();
        }, 20000);
      } else {
        clearInterval(interval.value);
        interval.value = null;
      }
    });

    watch(userUUID, (to, from) => {
      if (isRoleAdmin.value) {
        const { getOrders } = orderService(userUUID.value);
        // /**fetch orders  */
        getOrders();
        // Set interval to fetch orders every 20000 ms if current route is home
        if (route.name != "orders") {
          interval.value = window.setInterval(function () {
            getOrders();
          }, 20000);
        }

      }

    });

    onMounted(() => {


      // init user
      const userData = store.getters["auth/getUser"] ?? null;

      if (userData) {
        user.value.first_name = userData.first_name;
        user.value.last_name = userData.last_name;
        user.value.email = userData.email;
        selectedUser.value.storeName = localStorage.getItem('selectedUser', null) ? selectedUser.value.storeName : localStorage.getItem('selectedUser', null);
        if (!store.getters['auth/role']) {
          const { getStripeStatus, stripeState } = stripeSrevice(store.getters['auth/getUuid']);
          getStripeStatus().then(resp => {
            //if (stripeState && stripeState.data && !stripeState.data.active && stripeState.data.link && !stripeState.data?.active) {
            if (stripeState && !stripeState.data?.active) {
              stripeIsEnabled.value = stripeState?.data?.active;
              stripeLink.value = stripeState?.data?.link?.url;
            } else {
              stripeIsEnabled.value = stripeState?.data?.active;
              stripeMessage.value = 'Stripe est activé'
            }
          }).catch(err => {
            stripeMessage.value = 'Error activating Stripe account';
          });
        }
      }
      // const stripeActivated = Cookies.get('stripeActivated');
      // if (stripeActivated === 'true') {
      //   activated.value = true;
      // }

    });
    onUnmounted(async () => {
      clearInterval(interval.value);
    });
    watch(orderToPrint, (newValue, oldValue) => {
      if (newValue && isRoleAdmin.value && currentRouteName.value != 'orders') {
        playSounds();
      }
    });

    watch(store.getters['auth/selectedUser'], (newValue, oldValue) => {
      selectedUser.value.storeName = newValue;
    });

    return {
      selectedUser,
      user,
      stripeLink,
      stripeIsEnabled,
      stripeMessage,
      message: "",
      // activateStripe,
      stripeState,
      //activated,
      countNewOrders,
      isRoleSuperAdmin,
      isRoleAdmin,
      userUUID,
      audio,
      stopAudios,
      // getUID


      countPendingOrders,
      stopAudio,

    };
  },
  computed: {
    // ...mapGetters({ getRole: 'auth/role', getUuid: 'auth/getUuid' }),
    ...mapState(['cartStore']),
    // getUID() {
    //   return router.currentRoute.value.params.UserUUID;
    // },
  },
  props: {
    stripe: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ""
    }
  },
  methods: {
    // Sign out method
    async logOut() {
      await this.$store.dispatch("auth/logout").then(
        () => {
          localStorage.removeItem('cart')
          window.location.href = "/login"
        },
        (error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.$swal({
            title: this.message,
            icon: "error",
            position: "top-end",
            timer: "5000",
            allowOutsideClick: false,
            showConfirmButton: false,
            toast: true,
            showDenyButton: false,
            showCancelButton: false,
            showCloseButton: true,
            timerProgressBar: true,
          });
        }
      );
    },
    toggle() {
      // Select the body element
      var body = document.querySelector("body");

      // Toggle the classes on .sidebar-header .sidebar-toggler
      var sidebarToggler = document.querySelector(".sidebar-header .sidebar-toggler");
      sidebarToggler.classList.toggle("active");
      sidebarToggler.classList.toggle("not-active");

      // Check media query conditions using window.matchMedia

      if (window.matchMedia("(min-width: 992px)").matches) {
        body.classList.toggle("sidebar-folded");
      } else if (window.matchMedia("(max-width: 991px)").matches) {
        body.classList.toggle("sidebaropen");
      }

    },
  },
};

</script>
<style lang="scss">
@import "~@/sass/theme/light.scss";
@import "~@/sass/theme/dark.scss";
@import "~@/sass/variables.scss";

.pointer {
  cursor: pointer;
}

.navbar {
  width: calc(100% - #{$sidebar-width-lg});
  height: $navbar-height;
  background: $card-bg;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  right: 0;
  left: $sidebar-width-lg;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;

  @media (max-width: 991px) {
    width: 100%;
    left: 0;

    .navbar-content {
      width: calc(100% - #{$sidebar-folded-width} - 1px);
    }
  }

  .sidebar-toggler {
    height: 100%;
    border-right: 1px solid $border-color;
    align-items: center;
    padding: 0 25px;
    display: none;

    svg {
      width: 20px;
      height: 20px;
      color: $text-muted;
    }

    @media (max-width: 991px) {
      display: flex;
    }
  }

  .search-form {
    @extend .d-none !optional;
    @extend .d-md-flex !optional;
    @extend .align-items-center !optional;
    width: 100%;
    margin-right: 60px;

    .input-group {
      .input-group-text {
        padding: 0;
        border: 0;
        color: $text-muted;
        background: $input-bg;

        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .form-control {
        border: 0;
        margin-top: 3px;
      }
    }
  }

  .text-wh {
    color: #FFF;
  }

  .navbar-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;

    @media (max-width: 991px) {
      width: calc(100% - #{$sidebar-folded-width} - 1px);
    }

    .navbar-nav {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      .nav-item {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 30px;
        display: flex;
        align-items: center;

        .nav-link {
          color: $body-color;
          padding: 0;
          position: relative;
          margin-left: auto;
          margin-right: auto;

          &:hover,
          &[aria-expanded="true"] {
            color: $primary;
          }

          &::after {
            display: none;
          }

          svg {
            width: 20px;
            height: 20px;
          }

          .indicator {
            position: absolute;
            top: 0px;
            right: 2px;

            .circle {
              background: $primary;
              width: 7px;
              height: 7px;
              border-radius: 50%;

              &::before {
                background-color: $primary;
                content: "";
                display: table;
                border-radius: 50%;
                position: absolute;
                @extend .pulse !optional;
              }
            }
          }
        }

        &.dropdown {
          width: 55px;

          @media (max-width: 767px) {
            position: static;
          }

          .dropdown-menu {
            width: max-content;
            position: absolute;
            right: -20px;
            left: auto;
            @extend .dropdownAnimation !optional;

            &::before {
              content: "";
              width: 13px;
              height: 13px;
              background: $dropdown-bg;
              position: absolute;
              top: -7px;
              right: 28px;
              transform: rotate(45deg);
              border-top: 1px solid $dropdown-border-color;
              border-left: 1px solid $dropdown-border-color;
            }

            @media (max-width: 767px) {
              right: 20px;
              width: calc(100% - 40px);

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}


.alerts {
  &-orders {
    position: relative;

    .order {

      &-numbers {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 14px;
        background: red;
        color: #fff;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}


.newOrders {
  background: linear-gradient(80deg, #0030e0, rgba(0, 115, 248, 0.6705882353)) !important;
  border: none !important;
  color: white !important;
  font-size: 24px;
  border-radius: 5px;

  width: 32px;
  height: 32px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.alerts-shakes {
  // background: linear-gradient(80deg, #0030e0, rgba(0, 115, 248, 0.6705882353)) !important;
  // border: none !important;
  // color: white !important;
  // font-size: 24px;
  // border-radius: 5px;
  animation: shake 0.08s ease-in-out infinite alternate;
  // width: 32px;
  // height: 32px;
  // display: flex !important;
  // justify-content: center !important;
  // align-items: center !important;
}

@keyframes shake {
  from {
    transform: translate(-5%, -5%) rotate(0deg);
  }

  to {
    transform: translate(-5%, -5%) rotate(10deg);
  }
}
</style>