// src/composables/useGlobal.ts
import { ref } from 'vue';
import dayjs from 'dayjs'
import {
    audio
} from '@/shared/state';
export function useGlobal() {
    const selectedImage = ref<string | null>(null);
    const error = ref<string | null>(null);

    const handleFileChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        const files = target.files;

        if (files && files.length > 0) {
            const file = files[0];

            if (file.type === 'image/png' || file.type === 'image/jpeg') {
                const reader = new FileReader();

                reader.onload = () => {
                    selectedImage.value = reader.result as string;
                };

                reader.onerror = () => {
                    error.value = 'There was an error reading the file.';
                };

                reader.readAsDataURL(file);
            } else {
                error.value = 'Please select a PNG or JPEG image.';
            }
        } else {
            error.value = 'No file selected.';
        }
    };
    // Function to play notification sound
    const playNotificationSound = async () => {
        try {
            if (!audio.value) {
                audio.value = new Audio(require('@/assets/sound/notification.wav')); // Path to your audio file
                audio.value.loop = true;
                await audio.value.play();
            }
        } catch (error) {
            // Handle any exceptions (e.g., browser restrictions) here
            console.error(error);
        }
    };
    // Function to play notification sound
    const stopAudio = async () => {
        if (audio.value) {
            audio.value.pause();
            audio.value.currentTime = 0; // rewind the sound to the beginning
            audio.value = null
        }
    };


    /** 
     *Method to format date using Day.js
     */
    const formatDate = (dateString) => {
        return dayjs(dateString).format('MM/DD/YYYY');
    };

    return {
        selectedImage,
        error,
        handleFileChange,
        playNotificationSound,
        formatDate, stopAudio
    };
}
