// src/composables/useImageConverter.ts
import { ref } from 'vue';
import { singleOrder } from '@/shared/state';
import {
    prepareOrder
} from '@/utils/global';
/** NEW MODAL */
import { createModal } from '@/services/modalService';
export function useModal() {
    const modalAddTable = createModal('modal_order');

    const openModal = (id, action, order) => {
        if (action == "add")
            if (id == 'modal_order')
                modalAddTable.show();
        singleOrder.data = order
        prepareOrder(singleOrder)
        console.log("singleOrder.data modal_order")
        console.log(singleOrder.data)
    }
    const closeModal = (id, action) => {
        if (action == "add")
            if (id == 'modal_order') {
                modalAddTable.hide();
                singleOrder.data = null
            }
    }
    return {
        closeModal,
        openModal,
    };
}
