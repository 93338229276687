import { ref, reactive } from 'vue';
import { useAuthenticatedFetch } from '../auth/useAuthenticatedFetch';
import { Order, OrdersByStatus, Orders } from '@/interfaces/order';
import { useGlobal } from "@/composables/global/useGlobal"
import { usePrint } from "@/composables/global/usePrint"
import {

    selectedTypePrinter, printer,
    allOrdersHistory, allOrders, printOrder,
    countAllOrdersHistory, countPendingOrders,
    countPreparedOrders, countDelivredOrders,
    countAllOrders, userUUID, paginationLinks
} from '@/shared/state';



const receiveOrders = reactive<Orders>({
    data: []
});
const previousOrders = reactive<Orders>({
    data: []
});
const { authenticatedFetch } = useAuthenticatedFetch();
const API_URL = process.env.VUE_APP_ROOT_API + 'api/';
const { playNotificationSound } = useGlobal();
const { printOrderTicket, printOrderTicketByElementOrder } = usePrint();
export function useOrder() {
    previousOrders.data = [];
    const error = ref<string | null>(null);
    const fetchOrders = async () => {
        try {
            receiveOrders.data = []
            const response = await authenticatedFetch(`${API_URL}orders/unstructured/${userUUID.value}`, {
                method: 'GET'
            }, false);
            receiveOrders.data = response.data ?? []
            // // Concatenate all CAC orders
            // receiveOrders.data = receiveOrders?.data?.concat(orders?.data?.pending?.CAC || [], orders?.data?.accepted?.CAC || [], orders?.data?.delivered?.CAC || []);
            // // Concatenate all LIV orders
            // receiveOrders.data = receiveOrders?.data?.concat(orders?.data?.pending?.LIV || [], orders?.data?.accepted?.LIV || [], orders?.data?.delivered?.LIV || []);
            // // Concatenate all AT_PLACE orders
            // receiveOrders.data = receiveOrders?.data?.concat(orders?.data?.pending?.AT_PLACE || [], orders?.data?.accepted?.AT_PLACE || [], orders?.data?.delivered?.AT_PLACE || []);

            if (receiveOrders.data) {
                // count orders
                countAllOrders.value = receiveOrders?.data?.length || 0
                countPendingOrders.value = receiveOrders?.data?.filter(o => o.status == 'pending')?.length || 0
                countPreparedOrders.value = receiveOrders?.data?.filter(o => o.status == 'accepted')?.length || 0
                countDelivredOrders.value = receiveOrders?.data?.filter(o => o.status == 'delivered')?.length || 0
                // Check for new orders
                checkNewOrders(receiveOrders.data);
                receiveOrders.data.sort((a, b) => b.id - a.id);
                allOrders.data = receiveOrders.data
                previousOrders.data = receiveOrders.data
            }

        } catch (err: any) {
            error.value = err.message;
        }
    };
    const fetchOrdersHistory = async (page: number) => {
        try {
            const response = await authenticatedFetch(`${API_URL}historiques-orders/${userUUID.value}?page=${page}`, {
                method: 'GET'
            }, false);
            allOrdersHistory.data = response?.data?.data || []
            paginationLinks.value = response?.data;
            countAllOrdersHistory.value = response?.data?.total || 0

        } catch (err: any) {
            error.value = err.message;
        }
    };

    const updateStatusOrder = async (body: any, currentStatus: string) => {
        try {
            const response = await authenticatedFetch(`${API_URL}orders/${userUUID.value}/status`, {
                method: 'POST',
                body: JSON.stringify(body),
            }, false);
            if (response) {
                const isOrderItemFound = allOrders?.data?.find(item => item.id === body?.order_id) ?? null
                const isHistoryItemFound = allOrdersHistory?.data?.find(item => item.id === body?.order_id) ?? null
                if (isHistoryItemFound) {
                    isHistoryItemFound.status = body?.status
                }
                if (isOrderItemFound) {
                    isOrderItemFound.status = body?.status
                    if (body?.status == 'canceled' && currentStatus == 'pending') {
                        countPendingOrders.value--
                    }
                    if (body?.status == 'canceled' && currentStatus == 'accepted') {
                        countPreparedOrders.value--
                    }
                    if (body?.status == 'accepted') {
                        countPendingOrders.value--
                        countPreparedOrders.value++
                    }
                    if (body?.status == 'delivered') {
                        countPreparedOrders.value--
                        countDelivredOrders.value++
                    }
                }
            }
            return response
        } catch (error: any) {
            console.log(error)
        }
    };

    // Function to check for new orders
    const checkNewOrders = async (newOrders) => {
        const ordersToPrint: any = []
        newOrders.forEach((newOrder) => {
            const isNewOrderFound = !previousOrders?.data?.some(prevOrder => prevOrder.id === newOrder.id);
            const isPendingOrder = newOrder.status == 'pending' && !newOrder.printed;
            if (isNewOrderFound && isPendingOrder) {
                ordersToPrint.push(newOrder)
                // calling the bip sound when receive new order with status pending
                playNotificationSound()
                // change order printer status to true
                updateStatusPrintOrder({ "order_id": newOrder.id, "printed": 1 })
            }
        });
        console.log('selectedTypePrinter')
        console.log(selectedTypePrinter.value)
        // Trigger action to print ticket for the new pending order (SEND ARRAY OF ORDERS)
        if (selectedTypePrinter.value == "2") {
            printOrderTicketByElementOrder(ordersToPrint);
        } else if (selectedTypePrinter.value == "1") {
            printOrderTicket(ordersToPrint);
        }

    };

    const updateStatusPrintOrder = async (body: any) => {
        try {
            await authenticatedFetch(`${API_URL}orders/${userUUID.value}/printed`, {
                method: 'POST',
                body: JSON.stringify(body),
            }, false);
        } catch (error: any) {
            console.log(error)
        }
    };

    return {
        fetchOrders,
        fetchOrdersHistory,
        updateStatusOrder
    };
}
