<template>
    <button class="btn btn-secondary" @click.prevent="eventHandle(page)">
        Details
    </button>
</template>

<script setup>
import { defineEmits } from 'vue';

const emits = defineEmits(['details']);
const eventHandle = () => {
    emits('details');
};
</script>