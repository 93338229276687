
import { defineComponent, computed } from 'vue';
import { printOrder, userUUID, currentUser, storeInfoState } from '@/shared/state';


/*composables & states */
import { useGlobal } from "@/composables/global/useGlobal"

import {
    formatAddress,

} from '@/utils/global';

export default defineComponent({
    name: 'SomeComponent',
    setup() {
        const { formatDate } = useGlobal();
        const statePrintOrder = computed(() => printOrder.data);
        return {

            userUUID,
            currentUser,
            statePrintOrder,
            formatDate,
            formatAddress,

            storeInfoState

        };

    },

});
