import store from '@/store';
import axios from 'axios';
import router from '../router/index';

store.subscribe((mutation) => {
  if (mutation.type === 'auth/logout') {
    axios.defaults.headers.common["Authorization"] = '';
    localStorage.removeItem('user')
    router.push('/login')
  }
  if (mutation.type === 'auth/loginSuccess') {
    if (mutation.payload) {
      if (mutation.payload.accessToken) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${mutation.payload.accessToken}`;
        localStorage.setItem('user', JSON.stringify(mutation.payload));
      }
    } else {
      axios.defaults.headers.common["Authorization"] = '';
      localStorage.removeItem('user')
    }
  }
})


