
import { reactive, onMounted } from 'vue';
const modals = {};

function createModal(id) {
    modals[id] = reactive({
        instance: null,
    });

    onMounted(() => {
        modals[id].instance = new bootstrap.Modal(`#${id}`, {});
    });

    return {
        show: () => {
            modals[id].instance.show();
        },
        hide: () => {
            modals[id].instance.hide();
        },
    };
}

export { createModal };
