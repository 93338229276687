
// @ts-nocheck
import { mapActions, mapState, mapMutations, Store, mapGetters } from "vuex";
import ProductUtils from '../../utils/ProductUtils';
import CheckoutUtils from '../../utils/CheckoutUtils';
import DiscountUtils from '../../utils/DiscountUtils';
import Swal from 'sweetalert2';
import CustomModal from "./elements/CustomModal.vue"
/** import components */
import RadioButton from "./inputs/RadioButton.vue"
import CheckboxButton from "./inputs/CheckboxButton.vue"
import PlusMinusButton from "./inputs/PlusMinusButton.vue"

import { setDefaultImage, getImageUrl } from '../../utils/global';

export default {
    props: ["product", "action", "indexProduct"],
    components: {
        RadioButton,
        CheckboxButton,
        PlusMinusButton,
        CustomModal
    },
    data() {
        return {
            isModalAddToCartOpen: false,
            ProductUtils: ProductUtils,
            CheckoutUtils: CheckoutUtils
        };
    },
    setup() {
    },
    created() {
        this.isModalAddToCartOpen = true
        //console.log("Hello modal")
        //console.log(this.product)
    },

    methods: {
        setDefaultImage, getImageUrl,
        ...mapActions(['getLocalPanier', 'addToCart']),
        concatenateNamesOfIngredients(product) {
            return ProductUtils.concatenateNamesIngredients(this.product);
        },

        AddProductToCart() {
            let Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
            })
            let cart = localStorage.getItem("cart");
            cart = JSON.parse(cart)
            this.cartStore.products = this.storeData?.data?.uuid
            this.cartStore.products = cart?.products || [];
            this.cartStore.products.push(ProductUtils.checkGroupIsUsed(this.product));
            this.cartStore.subTotal = this.cartStore?.products.reduce(
                (sum, product) => sum + product.total,
                0
            );
            //this.cartStore.discountTotal = 0; // to do the function calcul discount
            /**Check if product has promo */
            DiscountUtils.isProductOnPromotion()
            /**Re-calculate total for all discounts */
            DiscountUtils.ReCalculateAllDiscounts();
            localStorage?.setItem("cart", JSON.stringify(this.cartStore));
            Toast.fire({
                icon: 'success',
                title: 'Le produit a été ajouté au panier',

            });
            this.closeModal();
        },
        UpdateProductInCart() {
            let cart = localStorage.getItem("cart");
            cart = JSON.parse(cart)
            this.cartStore.products = cart?.products || [];
            this.cartStore.products[this.indexProduct] = this.product;
            this.cartStore.subTotal = this.cartStore?.products.reduce(
                (sum, product) => sum + product.total,
                0
            );
            //this.cartStore.discountTotal = 0; // to do the function calcul discount
            /**Check if product has promo */
            DiscountUtils.isProductOnPromotion()
            /**Re-calculate total for all discounts */
            DiscountUtils.ReCalculateAllDiscounts();
            localStorage?.setItem("cart", JSON.stringify(this.cartStore));
            this.closeModal();
        },
        AddRemoveQteProduct(sign: string) {
            if (sign == "plus") this.product.quantity++;
            else if (sign == "minus")
                this.product.quantity =
                    this.product.quantity - 1 <= 0 ? 1 : this.product.quantity - 1;
            this.product.total = ProductUtils.totalProduct(this.product);
        },
        closeModal() {
            this.isOpen = false;
            this.isModalAddToCartOpen = false
            this.$emit("toggleAddToCart", false);
            document.body.classList.remove('modal-open');
        }
    },

    computed: {
        ...mapGetters(['getCartStore']),
        ...mapState(['cartStore', 'productsStore', 'restaurantStore', 'storeData']),
        checkQteMaxGroup() {
            let minCondition = 0;
            this.product?.groups.map((group: GroupOrder, index: number) => {
                if (group.totalQteSubProduct < group.min) {
                    minCondition++;
                }
            });
            return minCondition > 0 ? false : true;
        },
        checkQteMinGroup() {
            let maxCondition = 0;
            this.product?.groups.map((group: GroupOrder, index: number) => {
                if (group.totalQteSubProduct > group.max && group.max != null) {
                    maxCondition++;
                }
            });
            return maxCondition > 0 ? false : true;
        },
        isDisabled() {
            return !this.checkQteMaxGroup || !this.checkQteMinGroup;
        },
    },
};
