
// @ts-nocheck
import ProductUtils from "../../../utils/ProductUtils";
export default {
  props: ["product", "group", "subProduct"],
  setup(props) {
    let checkedSubProduct = [];
    checkedSubProduct.push(
      props.group.sub_products.filter(
        (subProductObj: any) => subProductObj.checked == true
      )
    );
    return {
      checkedSubProduct,
    };
  },
  data() {
    return {};
  },
  created() { },
  methods: {
    CheckboxButtonSubProduct() {
      this.subProduct.checked = !this.subProduct.checked;
      this.product.total = ProductUtils.totalProduct(this.product);
      if (this.group.type == 'ing') {
        this.product.ingredient_names = ProductUtils.concatenateNamesIngredients(
          this.group
        );
      }
      this.group.used = false;
    },
  },
  computed: {},
};
