
/* eslint-disable */
import { mapActions, mapState, mapMutations, Store, mapGetters } from "vuex";
import ProductUtils from '../../utils/ProductUtils';
import CheckoutUtils from '../../utils/CheckoutUtils';
import DiscountUtils from '../../utils/DiscountUtils';
import router from "@/router/index";
import Swal from "sweetalert2";


/* eslint-disable */
export default {
    data() {
        return {
            currentProduct: null,
            isModalAddToCartOpen: false,
            indexProduct: 0,
            ProductUtils: ProductUtils,
            CheckoutUtils: CheckoutUtils,
            isModalTableOpen: false,
            openCartMobile: false,



        };
    },
    components: {
    },
    mounted() {
        //console.log("Component cart")
        // @ts-ignore
        //console.log(this.$store.state.productsStore)


    },

    methods: {
        ...mapActions(['getLocalPanier', 'addToCart', 'getTables']),
        PreparerYourProduct(product, indexP) {
            // @ts-ignore
            this.$store.state.productsStore.currentProduct = product;
            // @ts-ignore
            this.isModalAddToCartOpen = true;
            // @ts-ignore
            this.indexProduct = indexP;
        },

        PlusMinusQteProduct(sign, product, indexProduct) {
            let Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
            })

            let title = "";
            if (sign == "plus") {
                product.quantity++;
            } else if (sign == "minus") {
                if (product.quantity == 1) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Le produit a été retiré', // Customize the title as needed

                    });
                    product.quantity--;
                    // @ts-ignore
                    this.cartStore?.products.splice(indexProduct, 1);
                } else {
                    product.quantity--;
                }
            }
            product.total = ProductUtils.totalProduct(product);
            // @ts-ignore
            this.cartStore.subTotal = this.cartStore?.products.reduce(
                (sum, productObj) => sum + productObj.total,
                0
            );
            /**Check if products has promo */
            DiscountUtils.isProductOnPromotion()
            /**Re-calculate total for all discounts */
            DiscountUtils.ReCalculateAllDiscounts();
            // @ts-ignore
            if (this.cartStore?.products.length) {
                localStorage?.setItem(
                    "cart",
                    // @ts-ignore
                    JSON.stringify(this.cartStore),
                );
            } else {
                localStorage?.removeItem("cart");
            }


        },
        handleToggleAddToCart(payload) {
            // @ts-ignore
            this.isModalAddToCartOpen = payload;
        },
        handleToggleSelectTable(payload) {
            // @ts-ignore
            this.isModalTableOpen = payload;
        },
        async checkout() {
            let result = CheckoutUtils.checkIsAllGoodToOrder("order");
            // if (this.cartStore?.deliveryMode == "at_place") {
            //     this.isModalTableOpen = true;
            // }
            // else {
            // @ts-ignore
            // @ts-ignore
            //if (result && this.cartStore?.products?.length > 0) {
            if (this.cartStore?.products?.length > 0) {
                // if (this.cartStore?.deliveryMode == "at_place") {
                //      this.isModalTableOpen = true;
                // }
                // else {
                const userUUID = router.currentRoute.value.params.UserUUID
                //console.log(userUUID)
                window.location.href = "/store/" + userUUID + "/checkout";
            }
            // }
        },
        showCartMobile() {
            // @ts-ignore
            this.openCartMobile = !this.openCartMobile;
        },
        // closeModalTable() {
        //     this.isModalTableOpen = false;
        // }
    },
    computed: {
        ...mapGetters(['getCartStore']),
        ...mapState(['cartStore', 'productsStore', 'storeData', 'userStore'])
    }
};
