import store from '../store/storeOrder/index'

export default class StoreUtils {
  static isCustomerCanPlaceOrder() {
    if (!store.state.storeData?.data?.option?.is_open || !store.state.storeData?.data?.option?.is_store_open) {
      const title = 'Restaurant férmer' // nuxtApp.$i18n.t(`alerts.beforeOrder.closed`);
      return { status: false, message: title };
    }
    if (store.state.storeData?.data?.option) {
      if (
        (store.state.cartStore.deliveryMode == "liv" &&
          !store.state.storeData?.data?.option?.is_delivery_time) ||
        (store.state.cartStore.deliveryMode == "liv" &&
          !store.state.storeData?.data?.option?.order_delivery) ||
        (store.state.cartStore.deliveryMode == "cac" && !store.state.storeData?.data?.option?.is_cac_time) ||
        (store.state.cartStore.deliveryMode == "cac" && !store.state.storeData?.data?.option?.order_cac) ||
        (store.state.cartStore.deliveryMode == "at_place" &&
          !store.state.storeData?.data?.option?.is_at_place_time) ||
        (store.state.cartStore.deliveryMode == "at_place" &&
          !store.state.storeData?.data?.option?.order_at_place)
      ) {
        const isOrder = {
          liv: "Livraison non disponible",
          cac: "Emporter non disponible",
          at_place: "Sur place non disponible",
        }
        const title = isOrder[store.state.cartStore.deliveryMode]  // nuxtApp.$i18n.t(`alerts.beforeOrder.deliveryMode.${store.state.cartStore.deliveryMode}.isOrder`);
        return { status: false, message: title };
      }
    }
    const title = null;
    return { status: true, message: title };
  }
}
