// src/composables/usePrint.ts
import { ref } from 'vue';
import { useGlobal } from "@/composables/global/useGlobal"
const { formatDate } = useGlobal();


import { printOrder, printOrderElementOrder, userUUID } from '@/shared/state';
import {
  prepareOrder
} from '@/utils/global';

export function usePrint() {

  // Function to print ticket for the order
  const printOrderTicket = async (orders: any) => {
    for (const order of orders) {
      printOrder.data = order
      prepareOrder(printOrder)
      const button = document.querySelector('#btnPrintOrder') as HTMLButtonElement;
      if (button) {
        await new Promise(resolve => setTimeout(resolve, 800));
        button.click();
      }
    }
    setTimeout(function () {
    }, 800)
  };

  // Function to print ticket for the order
  const printOrderTicketByElementOrder = async (orders: any) => {
    for (const order of orders) {
      printOrder.data = order
      prepareOrder(printOrder)
      setTimeout(function () { }, 100)
      const button = document.querySelector('#btnPrintElementOrder') as HTMLButtonElement;
      if (printOrder?.data) {
        for (const elementOrder of printOrder?.data?.elements_order) {
          printOrderElementOrder.data = elementOrder;
          await new Promise(resolve => setTimeout(resolve, 800));
          button.click();
        }
      }
      setTimeout(function () { }, 800)
    }
  };
  return {
    printOrderTicket,
    printOrderTicketByElementOrder
  };
}
