
import { defineComponent, computed } from 'vue';
/*composables & states */
import { singleOrder, userUUID } from '@/shared/state';
export default defineComponent({
    name: 'order-details',
    setup() {
        const order = computed(() => singleOrder.data);
        return {
            userUUID,
            order
        };
    },
});
