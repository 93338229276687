import Swal from 'sweetalert2';
import { ref, reactive } from 'vue';
import { userUUID, loggedIn, currentUser, accessToken } from '@/shared/state';
const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
})
export function useAuthenticatedFetch() {
    //accessToken.value = localStorage.getItem('jwt') ?? '';
    const user = JSON.parse(localStorage.getItem('user') ?? "{}");
    if (user && user.accessToken) {
        accessToken.value = user.accessToken

    }
    const authenticatedFetch = async (url: string, options: RequestInit = {}, alert: boolean) => {
        const headers = new Headers(options.headers || {});

        headers.set('Authorization', `Bearer ${accessToken.value}`);
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');
        const response = await fetch(url, {
            ...options,
            headers,
        });
        if (!response.ok) {
            if (response.status === 422) {
                Toast.fire({
                    title: "pack already existe",
                    icon: 'error',
                });
            } else if (response.status === 404) {
                Toast.fire({
                    title: "subscription not found",
                    icon: 'error',
                });
            } else if (response.status === 500) {
                Toast.fire({
                    title: "storage selected club uuid not existe",
                    icon: 'error',
                });
            }
            throw new Error(`Request failed: ${response.statusText}`);
        } else {
            if (response.status === 201 && alert && options?.method === "POST") {
                Toast.fire({
                    title: "added",
                    icon: 'success',
                });
            }
            if (response.status === 200 && alert && options?.method === "DELETE") {
                Toast.fire({
                    title: "deleted",
                    icon: 'success',
                });
            }
            if (response.status === 200 && alert && options?.method === "PUT") {
                Toast.fire({
                    title: "Edited",
                    icon: 'success',
                });
            }
        }
        return response.json();
    };

    return { authenticatedFetch };
}
