
// @ts-nocheck
import ProductUtils from "../../../utils/ProductUtils";
export default {
  props: ["product", "group", "subProduct"],
  methods: {
    increaseSubProductQuantity() {
      if (this.group.total_qte_sub_products < this.group.max || this.group.max == null) {
        if (this.subProduct.quantity >= 0) {
          if (
            this.subProduct.quantity < this.subProduct.max ||
            this.subProduct.max == 0 ||
            this.subProduct.max == null
          ) {
            this.subProduct.quantity += 1;
            this.subProduct.price = this.subProduct.unit_price * this.subProduct.quantity;
            this.group.total_qte_sub_products += 1;
            this.group.used = true;
            this.group.is_disabled =
              this.group.total_qte_sub_products == this.group.max ? true : false;
            this.subProduct.disabled_plus =
              this.subProduct.max == this.subProduct.quantity ||
                this.group.total_qte_sub_products == this.group.max
                ? true
                : false;
            this.product.total = ProductUtils.totalProduct(this.product);
          }
        }
      }
      this.product.enabled_to_order = ProductUtils.checkIsEnabledToOrder(this.product);
    },
    decreaseSubProductQuantity() {
      //Prodindex
      if (this.group.total_qte_sub_products > 0) {
        if (this.subProduct.quantity > 0) {
          this.group.used = true;
          this.subProduct.quantity -= 1;
          this.group.total_qte_sub_products -= 1;
          this.subProduct.price = this.subProduct.unit_price * this.subProduct.quantity;
          this.group.is_disabled =
            this.group.max > this.group.total_qte_sub_products ? false : true;
          this.subProduct.disabled_minus =
            this.subProduct.quantity == 0 || this.group.total_qte_sub_products == 0
              ? true
              : false;
          this.product.total = ProductUtils.totalProduct(this.product);
        }
      }
      if (this.subProduct.quantity == 0) {
        this.group.used = false;
      }
      this.product.enabled_to_order = ProductUtils.checkIsEnabledToOrder(this.product);
    },
  },
};
