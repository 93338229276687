import { ref, reactive } from 'vue';

export const countAllOrders = ref<number>(0);
export const countNewOrders = ref<number>(0);
export const orderToPrint = ref<any>(null);



import { SingleOrder, OrderResponse, Orders, SingleElementsOrder } from '@/interfaces/order';
import { Printer, PrinterResponse } from '@/interfaces/printer';

import router from '@/router/index'


// Create a reactive array to hold the packs
export const ordersHistory = reactive<OrderResponse>({
    data: {}
});


export const allOrders = reactive<Orders>({
    data: []
});
export const allOrdersHistory = reactive<Orders>({
    data: []
});
export const singleOrder = reactive<SingleOrder>({
    data: null
});
export const printOrder = reactive<SingleOrder>({
    data: null
});
export const printOrderElementOrder = reactive<SingleElementsOrder>({
    data: null
});

export const countPendingOrders = ref<number>(0);
export const countPreparedOrders = ref<number>(0);
export const countDelivredOrders = ref<number>(0);
export const countAllOrdersHistory = ref<number>(0);
export const currentStatusOngle = ref<string>('pending');

const getUserData = localStorage.getItem('currentUser') ?? null;
const parseUserData = getUserData ? JSON.parse(getUserData) : null
export const currentUser = ref(parseUserData || {});


const user = JSON.parse(localStorage.getItem('user') ?? "{}");

//const getAccessToken = localStorage.getItem('jwt') ?? null;
const parseAccessToken = user?.accessToken ? user.accessToken : null
export const accessToken = ref(parseAccessToken || '');

const storageUserUUID = localStorage.getItem('userUUID') ?? '';
export const userUUID = ref(storageUserUUID || '');
export const loggedIn = ref(storageUserUUID ? true : false)


export const paginationLinks = ref({});
export const storeInfoState = ref<any>(null);


/**
 * Printer
 */
export const printer = reactive<PrinterResponse>({
    data: {}
});
export const selectedTypePrinter = ref<string>('');
export const audio = ref<any>(null);