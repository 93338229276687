<template>
    <div class="  mt-5">
        <ul v-if="paginationLinks.last_page !== 1" class="pagination center">
            <li v-if="paginationLinks.current_page > 1">
                <a href="#" aria-label="Previous" @click.prevent="changePage(paginationLinks.current_page - 1)">
                    <span aria-hidden="true">«</span>
                </a>
            </li>
            <li v-for="page in pagesNumber" :key="page" :class="{ 'active': page === paginationLinks.current_page }">
                <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li v-if="paginationLinks.current_page < paginationLinks.last_page">
                <a href="#" aria-label="Next" @click.prevent="changePage(paginationLinks.current_page + 1)">
                    <span aria-hidden="true">»</span>
                </a>
            </li>
        </ul>

    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import { paginationLinks } from '@/shared/state';

const props = defineProps({
    pagination: Object,
    offset: {
        type: Number,
        default: 2 // Adjust default offset as per your requirement
    }
});

const emits = defineEmits(['page-change']);

const pagesNumber = computed(() => {
    if (!paginationLinks.value.to) {
        return [];
    }
    let from = paginationLinks.value.current_page - props.offset;
    if (from < 1) {
        from = 1;
    }
    let to = from + (props.offset * 2);
    if (to >= paginationLinks.value.last_page) {
        to = paginationLinks.value.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
        pagesArray.push(page);
    }
    return pagesArray;
});

const changePage = (page) => {
    emits('page-change', page);
};
</script>

<style scoped>
.center {
    text-align: center;
}

.pagination {
    justify-content: center;
    align-items: center;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    margin: 0 4px;
}

.pagination li.active a {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}
</style>
