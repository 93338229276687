import store from '@/store';
import { StripeResponse, LinkStripe } from './stripe.interface';
import { reactive } from '@vue/reactivity';
import axios from 'axios';
import router from '@/router';

interface fetchdata<T> {
  isLoading: boolean;
  error?: string;
  data: true | null;
}

export const stripeSrevice = (uuid: string | string[]) => {
  // ------ About ------
  // State
  const stripeState = reactive<fetchdata<StripeResponse<LinkStripe>>>({
    isLoading: false,
    error: '',
    data: null

  });
  //console.log('stripeState.data =>', stripeState.data)
  //console.log('stripeState =>', stripeState)

  //console.log("responseData =>")


  function otherUserPage() {
    return store.getters['auth/role'];
  }

  // Get Store
  const getStripeStatus = async () => {
    const isOtherUserPage = otherUserPage();

    // If the user is on otherUserPage, deactivate the stripe feature
    if (isOtherUserPage) {
      stripeState.isLoading = false;
      stripeState.data = null;
    } else {
      stripeState.isLoading = true;
      try {
        await axios.get(`stripe/active/${uuid}`).then(async response => {
          if (response.status === 200) {
            const json = await response.data;
            stripeState.data = json;
            stripeState.isLoading = false;
          }
        }).catch(error => {
          if (error.response) {
            stripeState.error = error.response.data.message;
            stripeState.isLoading = false;
            stripeState.data = null;
          } else {
            stripeState.error = error;
            stripeState.isLoading = false;
            stripeState.data = null;
          }
        })
      } catch (error: any) {
        stripeState.error = error;
        stripeState.isLoading = false;
        stripeState.data = null;
      }
    }
  }

  return { getStripeStatus, stripeState };
}

// Déclarer la fonction activateStripeAccount à l'extérieur de stripeService
/*export async function activateStripeAccount(uuid: string) {
  try {
    //console.log('activateStripeAccount')
    const response = await axios.get(`stripe/active/${uuid}`);
    //console.log(response);
    const responseData = response.data;

    if (responseData.active) {
      // Stripe account activation was successful
      //console.log('Stripe Activated Successfully');
    } else if (responseData?.link) {
      // Stripe account activation is incomplete, redirect the user to the provided link
      window.location.href = responseData?.link?.url;
    } else {
      // Handle unexpected response data 
      console.error('Unexpected response data:', responseData);
    }
    return responseData
  } catch (error) {
    // Handle API request errors 
    console.error('Error activating Stripe account:', error);
  }
}*/
