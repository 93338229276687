import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "elementRadio radioColor" }
const _hoisted_2 = ["value", "name"]
const _hoisted_3 = {
  key: 0,
  class: "font-weight-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Promos = _resolveComponent("Promos")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.RadioButtonsubProduct && $options.RadioButtonsubProduct(...args))),
      type: "radio",
      value: $props.subProduct.id,
      name: $props.group.title,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.checkedProductID) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelRadio, $setup.checkedProductID]
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(["font-weight-300 first-letter-maj", { checked: $props.subProduct.checked }])
    }, [
      _createTextVNode(_toDisplayString($props.subProduct?.ingredient?.name || $props.subProduct?.supplement?.name || $props.subProduct?.name) + " ", 1),
      ($props.subProduct.price > 0)
        ? (_openBlock(), _createElementBlock("b", _hoisted_3, " €" + _toDisplayString($props.subProduct.price), 1))
        : _createCommentVNode("", true),
      ($setup.promoSize)
        ? (_openBlock(), _createBlock(_component_Promos, {
            key: 1,
            promoSize: $setup.promoSize
          }, null, 8, ["promoSize"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}