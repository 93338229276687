// @ts-nocheck
import { SingleOrder, ElementsOrder } from '@/interfaces/order';
import { singleOrder, audio } from '@/shared/state';

import store from '../store/storeOrder/index'
export function setDefaultImage(event, defaultImage, imagePath) {
  const img = new Image();
  img.src = imagePath;
  img.onerror = () => {
    event.target.src = process.env.VUE_APP_DEFAULT_IMAGE
  };
}

export function setDefaultBackgroundImage(event, defaultImage, imagePath) {
  const img = new Image();
  img.src = imagePath;
  img.onerror = () => {
    event.target.style.backgroundImage = `url(${defaultImage})`;
  };
}

export function getImageUrl(pathImage) {
  //console.log(process.env.VUE_APP_ROOT_API)
  if (pathImage) {
    return process.env.VUE_APP_ROOT_API + pathImage;
  } else {
    return process.env.VUE_APP_DEFAULT_IMAGE
  }
}


export function openSideRight() {
  store.state.isSideRightOpening = !store.state.isSideRightOpening
  store.state.isSideLeftOpening = false
  if (store.state.isSideRightOpening) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
    document.querySelector('.liv-dropleft-cart').classList.remove('open-cart')
  }
}


export function openSideLeft() {
  store.state.isSideLeftOpening = !store.state.isSideLeftOpening
  store.state.isSideRightOpening = false
  if (store.state.isSideLeftOpening) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
    document.querySelector('.liv-dropright-cart').classList.remove('open-cart')
  }
}


export async function playSounds() {
  try {
    if (!audio.value) {
      audio.value = new Audio("/sound/notification.wav");
      audio.value.loop = true;

      await audio.value.play();
    }
  } catch (error) {
    // Handle any exceptions (e.g., browser restrictions) here
    console.error(error);
  }
}

export function stopAudios() {
  if (audio.value) {
    audio.value.pause();
    audio.value.currentTime = 0; // rewind the sound to the beginning
    audio.value = null
  }
}




export const formatAddress = (address: string | undefined) => {
  // Utilisez la méthode formatAddress pour remplacer les tirets par des espaces
  if (address) {
    return address.replace(/-/g, ' ');
  }
  return '';
};


/***** 
 * Order details
 */
export const prepareOrder = async (orderState: SingleOrder) => {
  await changeValuesOrder(orderState)
  const total = orderState.data.totalPriceOrder
  const total_amount = orderState?.data?.total_amount || 0
  const fees = orderState?.data?.fees || 0
  const loyalty_card = orderState?.data?.loyalty_card
  const discountLoyalty = DiscountLoyaltyCard(total, total_amount, fees, loyalty_card)
  const promocode = orderState?.data?.promocode || null
  const discountCodepromo = DiscountCodePromo(total, promocode);
  orderState.data.discountLoyalty = discountLoyalty
  orderState.data.discountCodepromo = discountCodepromo
  orderState.data.totalDiscount += (discountCodepromo) + (discountLoyalty)
  orderState.data.totalPriceOrder = total
}


export const DiscountLoyaltyCard = (totalPriceOrder: number, total_amount: number, fees: number, loyalty_card: object) => {
  if (totalPriceOrder > 0 && loyalty_card != null) {
    const discountPercentage = loyalty_card.percentage;
    const totalOrder = total_amount - fees;
    const discountDecimal = discountPercentage / 100;
    // Calculate the original total amount
    const originalTotal = totalOrder / (1 - discountDecimal);
    const discountAmount = originalTotal * discountDecimal;
    return discountAmount;
  }
  return 0;
}
export const DiscountCodePromo = (totalPriceOrder: number, promocode: any) => {
  if (totalPriceOrder > 0 && promocode !== null) {
    return parseFloat(totalPriceOrder * (promocode.percentage / 100))
  }
  return 0;
}

export const productPriceOrdered = (element: ElementsOrder) => {
  let price = element.price;
  element.sub_elements_order.map(function (sub_element_order, IndexGrp) {
    if (sub_element_order.type == "size") {
      price = sub_element_order.price;
    }
  });
  element.unitePrice = price;
  return price;
}

export const changeValuesOrder = async (orderState: SingleOrder) => {
  let name = ''
  let totalDiscountPromotions = 0
  let total = 0
  if (orderState?.data?.elements_order != undefined) {
    for (const element of orderState.data.elements_order) {
      //  orderState.data.elements_order.forEach(async (element: ElementsOrder, index: number) => {
      // total price order
      console.log("element")
      console.log(element)
      total += element.total_amount_element_subelements;

      name = ''
      // prepare element product name
      name = element?.product?.category?.name + ' - ' + element?.product?.name;
      element.sub_elements_order.map(function (sub_element_order, IndexGrp) {
        if (sub_element_order.type === "size") {
          name = name + "  " + sub_element_order.sub_product.name;
        }
      });
      element.finalName = name

      // get new price if product has type size (get price of size product)
      let price = element.price;
      element.sub_elements_order.map(function (sub_element_order, IndexGrp) {
        if (sub_element_order.type == "size") {
          price = sub_element_order.price;
        }
      });
      element.unitePrice = price;
      element.totalPrice = 0 //price * element.quantity;

      // calculate discount promotion
      let discountPromotion = 0;

      if (element.promo) {
        console.log("element.promo")
        console.log(element.promo)

        discountPromotion = await calculateDisounctPromotions(
          element.quantity,
          element.unitePrice,
          0,
          element.promo
        );
        element.discountPromotion = discountPromotion?.promoResult;
        totalDiscountPromotions += discountPromotion?.promoResult?.discount || 0
        console.log("element.discountPromotion")
        console.log(element.discountPromotion)
        console.log("discountPromotion?.discount")
        console.log(discountPromotion?.promoResult?.discount)
      }
    }
    orderState.data.totalPriceOrder = total
    orderState.data.totalDiscount = totalDiscountPromotions
    console.log("orderState.data.totalDiscount")
    console.log(orderState.data.totalDiscount)
    console.log("totalDiscountPromotions")
    console.log(totalDiscountPromotions)
  }


}

export const calculateDisounctPromotions = async (quantityProduct: number, priceProduct: number, TotalPriceWithSubProducts: number, Promo: object): Promise<{ promoResult: any }> => {



  return new Promise((resolve) => {

    setTimeout(() => {

      const quantityMin = Promo.min;
      const quantityFree = Promo.free;
      const discountPercentage = Promo.percentage;

      const promoResult = {
        priceProduct: priceProduct,
        quantityProduct: quantityProduct,
        quantityToGive: 0,
        quantityToSell: 0,
        discountPercentage: discountPercentage,
        totalPriceWithoutDiscount: (quantityProduct * priceProduct),
        priceProductDiscounted: 0,
        discount: 0,
        TotalPriceWithSubProducts: TotalPriceWithSubProducts,
        text: '',
        status: false
      };

      if (quantityMin == 0 && quantityFree == 0 && discountPercentage < 100) {
        promoResult.quantityToGive = 0;
        promoResult.discountPercentage = discountPercentage;
        promoResult.quantityToSell = quantityProduct;
        promoResult.priceProductDiscounted = quantityProduct * priceProduct * (1 - (discountPercentage / 100));
        promoResult.discount = quantityProduct * priceProduct * ((discountPercentage / 100));
        promoResult.text = (discountPercentage == 100) ? ' Produit(s) gratuit ' : "-" + discountPercentage + '%';
        promoResult.status = true
      } else if (quantityProduct >= quantityMin && quantityMin > 0 && quantityFree > 0) {
        const quantityToGive = parseInt((quantityProduct / quantityMin)) * quantityFree;
        const quantityToSell = quantityProduct - quantityToGive;
        const discount = quantityToGive * priceProduct * (discountPercentage / 100);
        // 2 * 
        const unitePrice = (quantityProduct * priceProduct) - discount;
        promoResult.quantityToGive = quantityToGive;
        promoResult.discountPercentage = discountPercentage;
        promoResult.quantityToSell = quantityToSell;
        promoResult.priceProductDiscounted = unitePrice;
        promoResult.discount = discount
        promoResult.text = (discountPercentage < 100) ? "-" + discountPercentage + '% sur ' + promoResult.quantityToGive + ' article(s)' : promoResult.quantityToGive + ' article(s) gratuit'
        promoResult.status = true
      }
      console.log("promoResult")
      console.log(promoResult)
      resolve({ promoResult });
    }, 100); // Simulated delay
  });
}



