<template>
    <button @click.prevent="eventHandle(page)">
        delete
    </button>
</template>

<script setup>
import { defineEmits } from 'vue';

const emits = defineEmits(['delete']);
const eventHandle = () => {
    emits('delete');
};
</script>