import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "divPlusMines" }
const _hoisted_2 = { class: "font-weight-300 first-letter-maj" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "font-weight-500" }
const _hoisted_5 = { class: "btnPlusMines" }
const _hoisted_6 = { class: "QteProduct" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, [
      _createElementVNode("span", _hoisted_2, _toDisplayString($props.subProduct?.supplement?.name || $props.subProduct?.name), 1),
      ($props.subProduct.unit_price > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("b", _hoisted_4, " €" + _toDisplayString($props.subProduct.unit_price), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", {
        class: _normalizeClass(["btnQte", {
        'btnDisabled cursor-no-drop ':
          $props.subProduct.quantity == 0 || $props.group.total_qte_sub_products == 0,
      }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.decreaseSubProductQuantity()))
      }, " - ", 2),
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("span", null, _toDisplayString($props.subProduct.quantity), 1)
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(["btnQte", {
        'btnDisabled cursor-no-drop ':
          $props.subProduct.quantity == $props.subProduct.max ||
          $props.group.total_qte_sub_products == $props.group.max,
      }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.increaseSubProductQuantity()))
      }, " + ", 2)
    ])
  ]))
}