
// @ts-nocheck
import { mapActions, mapState, mapMutations, Store, mapGetters } from "vuex";
import StoreUtils from '@/utils/StoreUtils.ts';
import ProductUtils from '@/utils/ProductUtils.ts';
import CheckoutUtils from '@/utils/CheckoutUtils.ts';
import { setDefaultImage, getImageUrl } from '@/utils/global.ts';
import DiscountUtils from "@/utils/DiscountUtils.ts";
import Swal from 'sweetalert2';
// @ts-nocheck
export default {
    data() {
        return {
            StoreUtils: StoreUtils,
            ProductUtils: ProductUtils,
            CheckoutUtils: CheckoutUtils,
            deliveryModeFixedTop: false,
            isModalAddToCartOpen: false,
            searchQuery: "",
            filteredCategories: [],
            queryAtPlaceExists: false
        }
    },
    /** Components */
    components: {
    },
    async mounted() {
        await this.fetchtDetailsStore()
        await this.fetchProducts()
        //console.log("this.categoriesData")
        //console.log(this.categoriesData)
        // @ts-ignore
        window.addEventListener("scroll", this.handleScroll);
        // @ts-ignore
        await this.getLocalPanier();

        this.cartStore.deliveryMode = 'liv'
        const urlParams = new URLSearchParams(window.location.search);
        const isParamAtPlace = urlParams.get('type');
        if (isParamAtPlace == 'at_place') {
            this.queryAtPlaceExists = true
            this.cartStore.table = { id: urlParams.get('table_id'), table_number: urlParams.get('table_number') }
            this.cartStore.deliveryMode = 'at_place'
        } else {
            this.cartStore.deliveryMode = this.cartStore?.table?.id && this.cartStore?.deliveryMode === 'at_place' ? 'at_place' : this.cartStore?.deliveryMode //!this.cartStore?.table?.id && this.cartStore?.deliveryMode === 'at_place' ? 'cac' : this.cartStore?.deliveryMode
            //   this.cartStore.deliveryMode = this.cartStore?.table?.id && this.cartStore?.deliveryMode !== 'at_place' ? 'at_place' : !this.cartStore?.table?.id && this.cartStore?.deliveryMode === 'at_place' ? 'cac' : this.cartStore?.deliveryMode
        }
        /***Get delivery mode from localstorage or from url type=at_place, if localstorage empty and url at_place doesn't existe initial delivery mode on CAC */
        // this.cartStore.deliveryMode =
        //     this.queryAtPlaceExists && this.cartStore?.deliveryMode !== 'at_place'
        //         ? 'at_place'
        //         : !this.queryAtPlaceExists && this.cartStore?.deliveryMode === 'at_place'
        //             ? this.cartStore?.deliveryMode : 'cac'



        /** For version mobile */
        this.deliveryModeFixedTop = window.location.hash ? true : false
        /** First get products by delivery mode found */
        this.filterByDeliveryMode(this.cartStore.deliveryMode)
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        setDefaultImage, getImageUrl,
        ...mapActions(['getLocalPanier', "fetchtDetailsStore", "fetchProducts"]),
        handleScroll() {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const scrollThreshold = 79;
            this.deliveryModeFixedTop = scrollPosition > scrollThreshold;
        },
        PreparerYourProduct(product: any) {
            //console.log("PreparerYourProduct")
            if (!this.isModalAddToCartOpen) {
                //console.log(this.isModalAddToCartOpen)
                //if (this.storeData.isOpenToOrder?.status) {
                this.productsStore.currentProduct = ProductUtils.prepareMyProduct(product);
                this.isModalAddToCartOpen = true;
                /* } else {
                    let option = {
                        showToast: true,
                        type: 'error',
                        toastMessage: this.storeData.isOpenToOrder?.message,
                        duration: 3200
                    }
                    //console.log(option)
                    this.$store.commit('showCustomNotification', option);
                } */
            }
        },
        handleToggleAddToCart(payload) {
            this.isModalAddToCartOpen = payload;
        },
        concatenateNamesOfIngredients(product) {
            return ProductUtils.concatenateNamesIngredients(product, false);
        },
        /**
         * 
         */

        async filterByDeliveryMode(mode) {
            let switchToDeliveryMode = true
            /** First check if cart has products not avaliable on the mode selected */
            let productsToDelete = ProductUtils.CheckingAvailabilityProducts(
                this.cartStore?.products,
                mode
            );
            let Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
            })
            /**If array productsToDelete has products delete them first before switch to delivery mode selected */
            if (productsToDelete?.length > 0) {
                let sweetOption = {
                    title: this.$t("alerts.productsToDelete.title"),
                    text: this.$t("alerts.productsToDelete.text"),
                    icon: 'icon',
                    alertButtons: [
                        { text: this.$t("button.yes"), action: 'confirm', color: "confirmColor" },
                        { text: this.$t("button.no"), action: 'cancel', color: "cancleColor" },
                    ],
                }
                const selectedAction = await this.$refs.dialogConfirmation.open(sweetOption);
                if (selectedAction == "confirm") {
                    this.cartStore.products = this.cartStore?.products.filter(product => !productsToDelete.includes(product));
                    // re-calculate total
                    this.cartStore.subTotal = this.cartStore?.products.reduce(
                        (sum, product) => sum + product.total,
                        0
                    );
                    switchToDeliveryMode = true
                    Toast.fire({
                        icon: 'success',
                        title: 'Certains produits a été retiré dans votre panier', // Customize the title as needed

                    });
                } else {
                    switchToDeliveryMode = false
                }
            }
            /* if switchToDeliveryMode = false, we don't neeed to swtich the delivery mode */
            if (switchToDeliveryMode) {
                let fees = this.storeData?.data?.feeses[0]?.price || 0;
                let deliveryTotal = mode == "liv" ? fees : 0;
                this.cartStore.deliveryMode = mode;
                this.cartStore.deliveryTotal = deliveryTotal;
                this.cartStore.table = mode == "at_place" ? this.cartStore.table : {};
                /** Filter list products by mode selected */
                this.filteredCategories = this.filteredCategoriesByName.map(category => ({
                    ...category,
                    products: category.products.filter(product => product[mode.toUpperCase()] === 1),
                }));
                /** Check if customer can make an order**/
                this.$store.state.storeData['isOpenToOrder'] = StoreUtils.isCustomerCanPlaceOrder();
                /**Check if products has promo */
                DiscountUtils.isProductOnPromotion()
                /**Re-calculate total for all discounts */
                DiscountUtils.ReCalculateAllDiscounts();
                /** After changing delivery mode, change it on localstorage with fees  */
                localStorage?.setItem("cart", JSON.stringify(this.cartStore));
            }
        },
        handleSearch() {
            this.filteredCategories = this.filteredCategoriesByName;
        },
        checkUrl(page) {
            const url = window.location.href;
            return url.includes(page);
        }
    },
    computed: {
        ...mapGetters(['getCartStore']),
        ...mapState(['cartStore', 'productsStore', 'storeData', 'categoriesData']),

        filteredCategoriesByName() {
            const query = this.searchQuery.toLowerCase();
            return this.categoriesData.filter(category => {
                return (
                    category.name.toLowerCase().includes(query) ||
                    category.products.some(product => {
                        return (
                            product.name.toLowerCase().includes(query)
                        );
                    })
                );
            });
        },
        // filteredCategoriesByName() {
        //     const query = this.searchQuery.toLowerCase();
        //     return this.categoriesData.reduce((results, category) => {
        //         const matchingProducts = category.products.filter(product => {
        //             return (
        //                 product.name.toLowerCase().includes(query)
        //             );
        //         });

        //         if (matchingProducts.length > 0) {
        //             // Include the category in results if there are matching products
        //             results.push({
        //                 ...category,
        //                 products: matchingProducts,
        //             });
        //         }
        //         return results;
        //     }, []);



        // },


    }
};
