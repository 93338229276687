
// @ts-nocheck
/** import components */
import Promos from "./Promos.vue";

/** import utils */
import ProductUtils from "../../../utils/ProductUtils";

export default {
  setup(props) {
    let promoSize = props.product.promos.filter(
      (promoObj: any) => promoObj.sub_product_id == props.subProduct.id
    );
    promoSize = promoSize.length > 0 ? promoSize[0] : null;
    const checkedProductID = props.subProduct.checked == 1 ? props.subProduct.id : 0;
    return {
      checkedProductID,
      promoSize,
    };
  },
  components: {
    Promos,
  },
  props: ["product", "group", "subProduct"],
  data() {
    return {};
  },
  created() { },
  methods: {
    RadioButtonsubProduct() {
      // Update the checked value of the radio button
      if (this.group?.type === "size" && this.group?.required) {
        this.group.sub_products.forEach((subProductObj: any) => {
          subProductObj.quantity = this.subProduct.id == subProductObj.id ? 1 : 0;
          subProductObj.checked = this.subProduct.id == subProductObj.id ? true : false;
        });
      }
      else {
        this.group.sub_products.forEach((subProductObj: any) => {
          subProductObj.quantity = this.subProduct.id == subProductObj.id && !subProductObj.checked ? 1 : 0;
          subProductObj.checked = this.subProduct.id == subProductObj.id && !subProductObj.checked
            ? true
            : false;
        });
      }
      this.group.used = ProductUtils.isGroupUsed(this.group);
      this.product.total = ProductUtils.totalProduct(this.product);
      this.product.enabled_to_order = ProductUtils.checkIsEnabledToOrder(this.product);
    },
  },
  computed: {},
};
