import store from '@/store'
import { reactive } from 'vue'
import axios from 'axios'
import router from '@/router/index';
import Swal from 'sweetalert2';
import { CustomerStore, CustomerStoreRequest, CustomerStoreResponse } from './customerStore.interface';
export interface FetchState<T> {
  isLoading: boolean;
  error?: string;
  data: T | null;
}
export const CustomerStoreService = (uuid: string | string[]) => {
  // State
  const CustomerStoreState = reactive<FetchState<CustomerStoreResponse<CustomerStore>>>({
    isLoading: false,
    error: '',
    data: null
  })
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  })

  CustomerStoreState.isLoading = true
  // ==> Methodes
  // Get all customers store
  const getCustomerStore = async () => {
    CustomerStoreState.isLoading = true
    CustomerStoreState.error = ''

    try {
      await axios.get('customerstore/' + uuid).then(response => {
        if (response.status === 200) {
          const json = response.data.data
          CustomerStoreState.data = json
          CustomerStoreState.isLoading = false
        }
      }).catch((error) => {
        if (error.response) {
          CustomerStoreState.error = error.response.data.message
          CustomerStoreState.isLoading = false
        } else {
          CustomerStoreState.error = error
          CustomerStoreState.isLoading = false
        }
      })
    } catch (error: any) {
      CustomerStoreState.error = error
      CustomerStoreState.isLoading = false

    }
  }

  const getCustomerStoreBlacklist = async () => {
    CustomerStoreState.isLoading = true
    CustomerStoreState.error = ''

    try {
      await axios.get('customerstore/' + uuid + "/blacklister").then(response => {
        if (response.status === 200) {
          const json = response.data.data
          CustomerStoreState.data = json
          CustomerStoreState.isLoading = false
        }
      }).catch((error) => {
        if (error.response) {
          CustomerStoreState.error = error.response.data.message
          CustomerStoreState.isLoading = false
        } else {
          CustomerStoreState.error = error
          CustomerStoreState.isLoading = false
        }
      })
    } catch (error: any) {
      CustomerStoreState.error = error
      CustomerStoreState.isLoading = false

    }
  }

  // Add new table
  const addCustomerStore = async (data: CustomerStoreRequest) => {
    // state.error = ''
    try {
      CustomerStoreState.isLoading = true
      CustomerStoreState.error = ''
      await axios.post('customerstore/' + uuid, data
      ).then(async (response) => {
        if (response.status === 200) {
          Toast.fire({
            icon: 'success',
            title: "Nouveau client ajouté"
          });
          CustomerStoreState.isLoading = false
          await getCustomerStore()
        }
      }).catch((error) => {
        if (error.response) {
          CustomerStoreState.error = error.response.data.message
          CustomerStoreState.isLoading = false
        } else {
          CustomerStoreState.error = error
          CustomerStoreState.isLoading = false
        }
      })
    } catch (error: any) {
      CustomerStoreState.error = error
      CustomerStoreState.isLoading = false

    }
  }
  // Update Table
  const updateCustomerStore = async (table: CustomerStoreRequest) => {
    CustomerStoreState.isLoading = true
    CustomerStoreState.error = ''
    await axios.put('customerstore/' + uuid, table).then(async (response) => {
      if (response.status === 200) {
        Toast.fire({
          icon: 'success',
          title: "Client modifié avec succès"
        });
        await getCustomerStore()
        CustomerStoreState.isLoading = false
      }
    }).catch((error) => {
      if (error.response) {
        CustomerStoreState.error = error.response.data.message
        CustomerStoreState.isLoading = false
      } else {
        CustomerStoreState.error = error
        CustomerStoreState.isLoading = false
      }
    })
  }
  // Detele table
  const removeCustomerStore = async (customerID: number) => {
    CustomerStoreState.isLoading = true
    CustomerStoreState.error = ''
    await axios.delete('customerstore/' + customerID)
      .then(async (response) => {
        if (response.status === 204) {
          Toast.fire({
            icon: 'success',
            title: "Client supprimé avec succès"
          });
          await getCustomerStore()
        }
      }).catch((error) => {
        if (error.response) {
          CustomerStoreState.error = error.response.data.message
          CustomerStoreState.isLoading = false
        } else {
          CustomerStoreState.error = error
          CustomerStoreState.isLoading = false
        }
      })
  }

  // //   Update Status product
  const blacklistCustomerStore = async (custome: CustomerStoreRequest) => {
    CustomerStoreState.isLoading = true
    CustomerStoreState.error = ''
    await axios.put('customerstore/blacklist/' + uuid, custome).then(async (response) => {
      if (response.status === 200) {
        Toast.fire({
          icon: 'success',
          title: "Client modifié avec succès"
        });
        await getCustomerStore()
        CustomerStoreState.isLoading = false
      }
    }).catch((error) => {
      if (error.response) {
        CustomerStoreState.error = error.response.data.message
        CustomerStoreState.isLoading = false
      } else {
        CustomerStoreState.error = error
        CustomerStoreState.isLoading = false
      }
    })
  }

  return {
    CustomerStoreState,
    getCustomerStore,
    getCustomerStoreBlacklist,
    addCustomerStore,
    updateCustomerStore,
    blacklistCustomerStore,
    removeCustomerStore,
  }
}
