import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API + "api/";

class UserService {
    getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    getUserBoard() {
        return axios.get(API_URL + 'user', { headers: authHeader() });
    }

    getModeratorBoard() {
        return axios.get(API_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(API_URL + 'admin', { headers: authHeader() });
    }
    getCitiesBycountry(country_code, keyword) {
        return axios.post('cities/likename', {
            country_code: country_code,
            name: keyword,
            page: 1
        });
    }
    getAddressBykeyWord(keyword) {
        return axios.post('customer/google_places', {
            query: keyword,
        });
    }
    getLatLongByPlaceID(place_id) {
        return axios.post('customer/get_lat_lng', {
            query: place_id
        });
    }
}

export default new UserService();