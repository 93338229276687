import { createI18n } from "vue3-i18n";

var lang = localStorage.getItem('selectedLang') || 'fr'
lang = lang != '' ? lang : 'fr'
const i18n = createI18n({
    locale: lang,
    messages: {
        fr: require('@/i18n/fr.json'),
        en: require('@/i18n/en.json')
    }
});
export default i18n;