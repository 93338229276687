<template>
    <button class="btn" :class="btnClass" @click.prevent="eventHandle(page)">
        {{ props?.title }}
    </button>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const emits = defineEmits(['default']);
const props = defineProps({
    title: String,
    color: String,
    btnClass: String

});


const eventHandle = () => {
    emits('default');
};
</script>