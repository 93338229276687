// @ts-nocheck
import store from '../store/storeOrder/index'
import ProductUtils from './ProductUtils';
export default class DiscountUtils {

    constructor(products) {
        this.products = products
    }

    static calculateDisounctPromotions(quantityProduct, priceProduct, TotalPriceWithSubProducts, Promo) {

        const quantityMin = Promo.min;
        const quantityFree = Promo.free;
        const discountPercentage = Promo.percentage;

        const promoResult = {
            priceProduct: priceProduct,
            quantityProduct: quantityProduct,
            quantityToGive: 0,
            quantityToSell: 0,
            discountPercentage: discountPercentage,
            totalPriceWithoutDiscount: (quantityProduct * priceProduct),
            priceProductDiscounted: 0,
            discount: 0,
            TotalPriceWithSubProducts: TotalPriceWithSubProducts,
            text: '',
            status: false
        };

        if (quantityMin == 0 && quantityFree == 0 && discountPercentage < 100) {
            promoResult.quantityToGive = 0;
            promoResult.discountPercentage = discountPercentage;
            promoResult.quantityToSell = quantityProduct;
            promoResult.priceProductDiscounted = quantityProduct * priceProduct * (1 - (discountPercentage / 100));
            promoResult.discount = quantityProduct * priceProduct * ((discountPercentage / 100));
            promoResult.text = (discountPercentage == 100) ? ' Produit(s) gratuit ' : "-" + discountPercentage + '%';
            promoResult.status = true
        } else if (quantityProduct >= quantityMin && quantityMin > 0 && quantityFree > 0) {
            const quantityToGive = parseInt((quantityProduct / quantityMin)) * quantityFree;
            const quantityToSell = quantityProduct - quantityToGive;
            const discount = quantityToGive * priceProduct * (discountPercentage / 100);
            // 2 * 
            const unitePrice = (quantityProduct * priceProduct) - discount;
            promoResult.quantityToGive = quantityToGive;
            promoResult.discountPercentage = discountPercentage;
            promoResult.quantityToSell = quantityToSell;
            promoResult.priceProductDiscounted = unitePrice;
            promoResult.discount = discount
            promoResult.text = (discountPercentage < 100) ? "-" + discountPercentage + '% sur ' + promoResult.quantityToGive + ' article(s)' : promoResult.quantityToGive + ' article(s) gratuit'
            promoResult.status = true
        }
        return promoResult;
    }





    static isProductOnPromotion() {
        store.state.cartStore.discountPromotions = 0
        store.state.cartStore?.products?.map((product, indexProduct) => {
            // The promotion applies only to the product price and does not include sub-products
            if (product?.promos?.length > 0 && product.quantity >= 1) {
                // This to get the real price of product ( I added this code to get price product if has type size  )
                const productPrice = ProductUtils.productPrice(product)
                // First check if the promotion has been affected to the product or the sub product
                let promoFound = product.promos.find((item) => item["product_id"] === product.id && item["sub_product_id"] == null) ? true : false
                if (!promoFound) {
                    product?.groups?.map((groupObj, indexGrp) => {
                        if (groupObj?.button_type == "RadioButton" && groupObj?.type == "size") {
                            promoFound = groupObj?.sub_products.find(
                                (subProductObj) => subProductObj?.checked && product?.promos[0].sub_product_id == subProductObj.id
                            ) ? true : false
                        }
                    });
                }
                // if promo found re-calculate discount
                if (promoFound) {
                    const promotions = this.calculateDisounctPromotions(
                        product.quantity,
                        productPrice,
                        0,
                        product.promos[0]
                    );
                    if (promotions?.status) {
                        product.promotion = promotions
                        store.state.cartStore.discountPromotions = store.state.cartStore.discountPromotions + product.promotion.discount
                    }
                }
            }
        })
    }

    /** Recalculate all discount [promotions, promocode, carte fidélité ] */
    static ReCalculateAllDiscounts() {
        let subTotal = store.state.cartStore?.subTotal;
        store.state.cartStore.totalFinal = subTotal;
        store.state.cartStore.discountCouponcode = store.state.cartStore.discountCouponcode || 0;
        store.state.cartStore.discountPromotions = store.state.cartStore.discountPromotions || 0;
        store.state.cartStore.discountFidelite = store.state.cartStore.discountFidelite || 0;
        const configLoyaltyCard = [] // store.state.storeData?.data?.config_loyalty || []
        const loyaltyCard = {} // store.state.userStore?.loyaltyCard || {};

        if (store.state.cartStore.discountPromotions > 0) {
            subTotal -= store.state.cartStore.discountPromotions;
        }

        // if (configLoyaltyCard[0]?.nb_orders - 1 == loyaltyCard?.nb_orders) {
        //     store.state.cartStore.discountFidelite = subTotal * (configLoyaltyCard[0]?.percentage / 100);
        //     subTotal -= store.state.cartStore.discountFidelite;
        // }

        if (store.state?.storeData?.couponCode?.percentage) {
            store.state.cartStore.discountCouponcode = subTotal * (store.state?.storeData?.couponCode?.percentage / 100);
            subTotal -= store.state.cartStore.discountCouponcode;
        }

        store.state.cartStore.totalFinal = subTotal + store.state.cartStore?.deliveryTotal;
        store.state.cartStore.discountTotal = store.state.cartStore?.discountFidelite + store.state.cartStore?.discountCouponcode + store.state.cartStore?.discountPromotions;

    }

}
