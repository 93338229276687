<template>
    <div v-if="show" class="showModal modal fade show" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-sm "
            style="visibility:visible;animation-duration:0.3s;animation-name:fadeInUp;">
            <div class="modal-content text-center pb_25 pt_25">
                <div class="modal-body">
                    <div v-if="sweetOption?.icon == 'valide'" class="order_valide">
                        <div class="text-center  icon_valide">
                        </div>
                    </div>
                    <h2 v-if="sweetOption?.title" class="custom-alert-title">{{ sweetOption.title }}</h2>
                    <p v-if="sweetOption?.text" class="custom-alert-text">{{ sweetOption.text }}</p>
                </div>
                <div class="custom-alert-buttons">
                    <button v-for="(button, index) in sweetOption.alertButtons" :key="index"
                        @click="onButtonClick(button.action)" class="custom-alert-button m-2" :class="button.color">
                        {{ button.text }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            resolve: null,
            reject: null,
            sweetOption: {},
        };
    },
    mounted() {
        // //console.log(this.show)
    },
    methods: {
        open(sweetOption) {
            this.sweetOption = sweetOption
            this.show = true;
            //console.log(sweetOption)
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.show = false;
        },
        onButtonClick(action) {
            this.resolve(action); // Resolve the Promise with the selected action
            this.close();
        },
    },
};
</script>

<style>
.showModal {
    display: block;
}

/* Add your custom styles here */
.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-alert {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
}

.custom-alert-title {
    margin: 0;
    font-size: 1.125em;
    color: #333;
}

.custom-alert-text {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
}

.custom-alert-button {
    background-color: #3085d6;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
</style>
