
// @ts-nocheck
import { mapActions, mapState, mapMutations, Store, mapGetters } from "vuex";
import ProductUtils from '../../utils/ProductUtils';
import CheckoutUtils from '../../utils/CheckoutUtils';

export default {
    data() {
        return {
            ProductUtils: ProductUtils,
            CheckoutUtils: CheckoutUtils,
            isModalTableOpen: false,
        };
    },
    components: {
    },
    async mounted() {
        //console.log("Component tables")
        await this.getTables()
    },
    methods: {
        ...mapActions(['getTables']),
        selectTable(table) {
            if (table?.id) {
                this.cartStore.table = { id: table?.id, table_number: table?.table_number };
                localStorage?.setItem("cart", JSON.stringify(this.cartStore));
                document.body.classList.remove('modal-open');
                if (this.userStore?.isLoggedIn) {
                    window.location.href = "/checkout";
                } else {
                    window.location.href = "/signin/?to=checkout";
                }
            }
        },
        closeModalTable() {
            this.$emit("toggleSelectTable", false);
        },
    },
    computed: {
        ...mapState(['cartStore', 'storeData', 'userStore'])
    }
};
