import AuthService from "../services/auth.service";
import { User } from "../services/users.service";

const initialState = {
  status: { loggedIn: false },
  role: false,
  user: null,
  selectedUser: null,
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }: any, user: any) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }: any) {
      return AuthService.logout()
        .then(
          (response) => {

            commit("logout");
            return Promise.resolve(response);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    register({ commit }: any, user: any) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    setSelectedUser({ commit }: any, user: any) {
      //console.log("GODD things 1");
      commit("setSelectedUser", user);
      //console.log("GODD things 2");
    },
    // recoverPassword({ commit } : any, user :any) {
    //   return AuthService.recoverPassword(user).then(
    //     response => {
    //       commit('emailSentSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       commit('recoverFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // resetPassword({ commit } : any, user :any) {
    //   return AuthService.resetPassword(user).then(
    //     response => {
    //       commit('recoverSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       commit('emailSentFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // verifyEmail({ commit } : any, user :any) {
    //   return AuthService.verifyEmail(user).then(
    //     response => {
    //       commit('emailVerificationDone');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       commit('emailVerificationFailed');
    //       return Promise.reject(error);
    //     }
    //   );
    // }
  },
  mutations: {
    loginSuccess(state: any, user: any) {
      // Set Stata After Login or from localStorage
      if (user) {
        state.status.loggedIn = true;
        state.user = user;
        state.role = user.data.roles;
      }
    },
    loginFailure(state: any) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state: any) {
      state.status.loggedIn = false;
      state.user = null;
      localStorage.removeItem("user");
    },
    registerSuccess(state: any) {
      state.status.loggedIn = false;
    },
    registerFailure(state: any) {
      state.status.loggedIn = false;
    },
    recoverSuccess(state: any) {
      // state.status.loggedIn = false;
    },
    setSelectedUser(state: any, selectedUser: any) {
      //console.log("GODD things 3" + selectedUser);
      state.selectedUser = selectedUser;
      //console.log("GODD things 4");
    },
    recoverFailure(state: any) {
      // state.status.loggedIn = false;
    },
    emailSentSuccess(state: any) {
      // state.status.loggedIn = false;
    },
    emailSentFailure(state: any) {
      // state.status.loggedIn = false;
    },
    emailVerificationDone(state: any) {
      // state.status.loggedIn = false;
    },
    emailVerificationFailed(state: any) {
      // state.status.loggedIn = false;
    },
  },
  getters: {
    // authenticate status
    isAuth(state: any) {
      return state.status.loggedIn === true;
    },
    // Get role (admin or super admin)
    role(state: any) {
      const role = Array(JSON.parse(state.role)).every((role) => {
        return role.includes("super-admin");
      });
      return role;
    },
    selectedUser(state: any) {
      //console.log("state.selectedUser",state.selectedUser);
      return state.selectedUser;
    },
    // Get user
    getUser(state: any): User {
      return state.user?.data;
    },
    // Get uuid
    getUuid(state: any): string {
      return state.user.data.uuid;
    },
  },
};
